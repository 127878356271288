table {
  caption-side: bottom;
  border-collapse: collapse;
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--border-color);
  font-size: small;
}

.table > :not(caption) > * > * {
  padding: 0.5rem;
  background-color: var(--bs-table-bg);
  background-image: linear-gradient(var(--bs-table-accent-bg), var(--bs-table-accent-bg));
  border-bottom-width: 1px;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

tbody td {
  width: 35%;
}

tbody th {
  text-align: left;
}

.table-responsive {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}
